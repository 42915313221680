import { gql } from "@apollo/client";

export const LINKTOKEN_LIST = gql`
  query allLinktokens(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: LinktokenFilter
  ) {
    items: allLinktokens(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      organization_id
      container_id
      parent_id
      type
      remote_id
      remote_name
      remote_token
      remote_token_alt
      enabled
      valid_token
      avatar_url
      created_at
      updated_at
      deleted
      last_poll_time
      next_poll_time
      last_backfill_time
      next_backfill_time
      prior_start_date
      prior_end_date
      error
      analytics_metrics_by_dimensions_dict
      tte_outcome
      __typename
    }
    total: _allLinktokensMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
    }
  }
`;

export const LINKTOKEN_ONE = gql`
  query Linktoken($id: ID!) {
    data: Linktoken(id: $id) {
      id
      parent_id
      organization_id
      organization {
        id
        name
      }
      container_id
      container {
        id
        name
      }
      user_id
      user {
        id
        first_name
        last_name
      }
      type
      remote_id
      remote_name
      enabled
      valid_token
      deleted
      avatar_url
      created_at
      updated_at
      last_poll_time
      next_poll_time
      last_backfill_time
      next_backfill_time
      prior_start_date
      prior_end_date

      scopes_granted
      scopes_denied
      fb_page_tasks

      lifetime_comments
      lifetime_followers
      lifetime_views
      lifetime_shares

      ga_account_id
      ga_selflink_url
      ga_internal_property_id

      analytics_metrics_by_dimensions_dict
      tte_outcome

      error
      __typename
    }
  }
`;
