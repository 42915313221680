/* eslint react/jsx-key: off */
import React from "react";
import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  NumberField,
  ReferenceField,
  BooleanField,
  useRecordContext,
} from "react-admin";

import JsonField from "../AlembicJsonFields/JsonField";

import LinktokenTitle from "./LinktokenTitle";
import Aside from "./Aside";
import FullNameField from "../FullNameField";
import FromNowDateField from "../FromNowDateField";
import IdAndNameField from "../IdAndNameField";
import ClassificationEventList from "../ClassificationEvent/ClassificationEventList";

/**
 * A react component for listing all classification events by linktoken type
 * @param {Object} props - Props object.
 * @params {array} props.types - An array of linktoken types to display for
 * @returns {JSX.Element} The ShowClassificationEventList component.
 */
const ShowClassificationEventList = (props) => {
  const record = useRecordContext();

  // the record can be empty while loading so we need an optional
  if (props.types.includes(record?.type)) {
    return (
      <>
        <h3>Classification Events</h3>
        <ClassificationEventList />
      </>
    );
  }

  return <></>;
};

const LinktokenShow = ({ ...props }) => {
  return (
    <Show title={<LinktokenTitle show />} aside={<Aside />} {...props}>
      <TabbedShowLayout>
        <Tab label="linktoken.form.basics">
          <TextField source="id" />
          <TextField source="remote_name" />
          <FromNowDateField label="Created At" source="created_at" showTime />
          <FromNowDateField label="Updated At" source="updated_at" showTime />
          <BooleanField source="valid_token" />
          <BooleanField source="enabled" />
          <BooleanField source="deleted" />
          <ReferenceField
            label="Parent Linktoken"
            source="parent_id"
            reference="Linktoken"
            link="show"
          >
            <IdAndNameField nameField="remote_name" />
          </ReferenceField>

          <ReferenceField
            label="Organization"
            source="organization_id"
            reference="Organization"
            link="show"
          >
            <IdAndNameField />
          </ReferenceField>

          <ReferenceField
            label="Owner"
            source="user_id"
            reference="User"
            link="show"
          >
            <FullNameField />
          </ReferenceField>
        </Tab>
        <Tab label="linktoken.form.ingest">
          <ReferenceField
            label="Container"
            source="container_id"
            reference="Container"
            link="show"
          >
            <IdAndNameField />
          </ReferenceField>
          <FromNowDateField
            label="Last Poll Time"
            source="last_poll_time"
            showTime
          />
          <FromNowDateField
            label="Next Poll Time"
            source="next_poll_time"
            showTime
          />
          <FromNowDateField
            label="Last Backfill Time"
            source="last_backfill_time"
            showTime
          />
          <FromNowDateField
            label="Next Backfill Time"
            source="next_backfill_time"
            showTime
          />
          <FromNowDateField
            label="Prior Start Date"
            source="prior_start_date"
            showTime
          />
          <FromNowDateField
            label="Prior End Date"
            source="prior_end_date"
            showTime
          />
          <TextField source="scopes_granted" />
          <TextField source="scopes_denied" />
          <TextField source="fb_page_tasks" />

          <BooleanField source="valid_token" />
          <p>
            <span className="MuiFormLabel-root">
              Errors (only applies if valid token is false)
            </span>
          </p>
          <JsonField source="error" />
        </Tab>
        <Tab label="linktoken.form.stats">
          <NumberField source="lifetime_comments" />
          <NumberField source="lifetime_followers" />
          <NumberField source="lifetime_views" />
          <NumberField source="lifetime_shares" />
        </Tab>
        <Tab label="Google Analytics">
          <TextField source="ga_account_id" />
          <TextField source="ga_selflink_url" />
          <TextField source="ga_internal_property_id" />
          <ShowClassificationEventList
            types={["GOOGLE_VIEW", "GOOGLE_WEBPROPERTY_G4"]}
          />
        </Tab>
        <Tab label="linktoken.form.adobe_analytics">
          <JsonField
            source="analytics_metrics_by_dimensions_dict"
            label="Dimensions"
            fullWidth
          />
          <ShowClassificationEventList types={["AA_SEGMENT"]} />
        </Tab>
        <Tab label="linktoken.form.tte">
          <TextField
            source="tte_outcome"
            label="TTE outcome stage name"
            fullWidth
          />
          <ShowClassificationEventList types={["AA_SEGMENT"]} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default LinktokenShow;
